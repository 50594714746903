import { RouteConfig, NavigationInstruction } from "aurelia-router";
import { Container } from 'aurelia-framework';
import { EventAggregator } from "aurelia-event-aggregator";
import { Operation } from "shared/utilities";
import { ModuleActivateFinishedEvent } from "shared/infrastructure/events/modules/activate-finished-event";
import { ModuleActivateStartedEvent } from "shared/infrastructure/events/modules/activate-started-event";

/**
 * Base for all aurelia modules in the application
 */
export abstract class BaseModule
{
	protected constructor()
	{
		this._eventAggregator = Container.instance.get<EventAggregator>(EventAggregator);
	}

	private readonly _eventAggregator: EventAggregator;

	/**
	 * The most recent update operation
	 */
	protected updateOperation: Operation;

	/**
	 * Custom activation of the module, when
	 * activated by the framework.
	 */
	protected abstract doActivate(params: any, routeConfig: RouteConfig, navigationInstruction: NavigationInstruction): void;

	/**
	 * Called by the framework when the module is activated.
	 * @returns A promise that will be resolved, when the module is activated.
	 */
	public activate(params: any, routeConfig: RouteConfig, navigationInstruction: NavigationInstruction): void
	{
		this._eventAggregator.publish("grandhood:module:activate:started", new ModuleActivateStartedEvent(routeConfig.name));

		this.doActivate(params, routeConfig, navigationInstruction);

		if (this.updateOperation != null)
		{
			this.updateOperation
				.promise
				.finally(() =>
				{
					if (this.updateOperation.error)
					{
						this._eventAggregator.publish("grandhood:module:activate:finished", new ModuleActivateFinishedEvent(routeConfig.name, "error", this.updateOperation.error));
					}
					else if (this.updateOperation.aborted)
					{
						this._eventAggregator.publish("grandhood:module:activate:finished", new ModuleActivateFinishedEvent(routeConfig.name, "aborted"));
					}
					else
					{
						this._eventAggregator.publish("grandhood:module:activate:finished", new ModuleActivateFinishedEvent(routeConfig.name, "success"));
					}
				});
		}
		else
		{
			this._eventAggregator.publish("grandhood:module:activate:finished", new ModuleActivateFinishedEvent(routeConfig.name, "success"));
		}
	}
}
