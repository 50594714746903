import { IdentityService } from "partner-dashboard/services/identity";
import { Router } from "aurelia-router";
import { autoinject } from "aurelia-framework";
import { Operation } from "shared/utilities";
import { ToastService, ModalService, IValidation } from "shared/framework";
import { ForgotPassword } from "./modals/forgot-password/forgot-password";
import { ResetPassword } from "./modals/reset-password/reset-password";
import { AcceptInvitation } from "./modals/accept-invitation/accept-invitation";
import { BaseModule } from "shared/framework/components/base-module/base-module";

interface LogInParams
{
	modal: "glemt-adgangskode" | "nulstil-adgangskode" | "ny-administrator" | undefined;
	token: string | undefined;
	id: string | undefined;
}

/**
 * Represents the module.
 */
@autoinject
export class LogInModule extends BaseModule
{
	/**
	 * Creates a new instance of the type.
	 */
	public constructor(
		identityService: IdentityService,
		toastService: ToastService,
		modalService: ModalService,
		router: Router)
	{
		super();

		this._identityService = identityService;
		this._identityService.unauthenticate();
		this._toastService = toastService;
		this._modalService = modalService;

		this._router = router;
		this._contructed = true;
	}

	private readonly _identityService: IdentityService;
	private readonly _toastService: ToastService;
	private readonly _modalService: ModalService;
	private readonly _router: Router;
	private readonly _contructed;

	/**
	 * The validation for the form.
	 */
	protected validation: IValidation;

	/**
	 * The email of the user
	 */
	protected email: string;

	/**
	 * The password of the user
	 */
	protected password: string;

	/**
	 * The most recent update operation
	 */
	protected updateOperation: Operation;

	/**
	 * Updates the page by fetching the latest data.
	 */
	public async onForgotPasswordClick(): Promise<void>
	{
		this._router.navigate("log-ind/glemt-adgangskode");
	}

	/**
	 * Called by the framework when the module is activated.
	 */
	public async doActivate(params: LogInParams): Promise<void>
	{
		this._modalService.closeAll("navigation");

		if (params.modal === "glemt-adgangskode")
		{
			await this._modalService.open(ForgotPassword).promise;
			this._router.navigate("log-ind");
		}
		else if (
			params.modal === "nulstil-adgangskode" &&
			params.token != null &&
			params.id != null)
		{
			let passwordReset = await this._modalService.open(ResetPassword, { token: params.token, id: params.id }).promise;

			if (!passwordReset)
			{
				this._router.navigate("log-ind");
			}
		}
		else if (
			params.modal === "ny-administrator" &&
			params.token != null &&
			params.id != null)
		{
			let adminSignUpPasswordReset = await this._modalService.open(AcceptInvitation, { token: params.token, id: params.id }).promise;

			if (!adminSignUpPasswordReset)
			{
				this._router.navigate("log-ind");
			}
		}
	}

	protected async logIn(): Promise<void>
	{
		// Returns if the object is not contructed.
		// This is needed because the `observable` decorator called the change handler when the
		// initial property value is set, which happens before the constructor is called
		if (!this._contructed)
		{
			return;
		}

		this.validation.active = true;

		if (!await this.validation.validate())
		{
			return;
		}

		if (this.updateOperation != null)
		{
			this.updateOperation.abort();
		}

		// Create and execute the operation
		this.updateOperation = new Operation(async signal =>
		{
			try
			{
				await this._identityService.logIn(this.email, this.password, signal);

				await this._identityService.initial();

				this._router.navigate("medarbejdere");
			}
			catch (error: any)
			{
				if ([401, 403].includes(error.response.status))
				{
					this._toastService.open(
						"error",
						{
							"message": "Vi kunne ikke genkende mail eller adgangskode. Har du glemt din adgangskode?"
						}
					)
				}
				else
				{
					this._toastService.open(
						"error",
						{
							"message": "Vi har tekniske problemer lige nu. Skriv på support@grandhood.dk hvis problemet bliver ved."
						}
					)
				}
				console.log("An error occurred.\n", error, signal);
			}
		})
	}
}
