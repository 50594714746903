import { IdentityService } from "partner-dashboard/services/identity";
import { autoinject, bindable, bindingMode } from "aurelia-framework";
import { ModalService, ThemeService } from "shared/framework";
import { Router } from "aurelia-router";

/**
 * Represents a menu at the top of the page.
 */
@autoinject
export class AppTopMenu
{
	public constructor(
		identityService: IdentityService,
		themeService: ThemeService,
		modalService: ModalService,
		router: Router)
	{
		this._identityService = identityService;
		this._router = router;
		this._themeService = themeService;
		this._modalService = modalService;
	}

	protected readonly _identityService: IdentityService;
	protected readonly _router: Router;
	protected readonly _themeService: ThemeService;
	protected readonly _modalService: ModalService;

	/**
	 * Whether or not the menu should be open (works on small devices)
	 */
	@bindable({ defaultBindingMode: bindingMode.twoWay })
	protected menuOpen: boolean;

	protected profileMenu: any;

	protected logOut(): void
	{
		this._identityService.identity.logout();
		this._router.navigate("log-ind");
	}
}
