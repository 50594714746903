import { autoinject } from "aurelia-framework";
import { Log, ApiClient } from "shared/infrastructure";
import settings from "resources/settings";
import { Identity } from "..";
import { DateTime, Duration } from "luxon";

/**
 * Represents a service that manages the authentication and identity of the user.
 */
@autoinject
export class IdentityService
{
	/**
	 * Creates a new instance of the type.
	 * @param apiClient The `ApiClient` instance.
	 */
	public constructor(apiClient: ApiClient)
	{
		this._apiClient = apiClient;
	}

	private readonly _apiClient: ApiClient;
	private _identity: Identity | undefined;
	private _refreshTokenTimeout: any;

	/**
	 * The identity of the currently authenticated user, or undefined if not authenticated.
	 */
	public get identity(): Identity
	{
		if (this._identity == null)
		{
			this._identity = new Identity();

			this.configureInfrastructure();
		}

		return this._identity;
	}

	/**
	 * Called when the user is authenticated, to configure the app.
	 */
	public authenticated(): void
	{
		this.configureInfrastructure();
		this.verifyAccessTokenExpireDate();
	}

	/**
	 * Attempts to start the session for logged in users
	 * @returns A promise that will be resolved with a boolean indicating whether reauthentication succeeded.
	 */
	public async startSession(): Promise<boolean>
	{
		try
		{
			await this.autoLogin();
		}
		finally
		{
			this.authenticated();
		}

		return Promise.resolve(this.identity.tokens != null);
	}

	/**
	 * Check the user as authenticated in the case
	 * of auto login and tokens
	 * @returns A promise that will be resolved when the operation succeeds.
	 */
	public async autoLogin(): Promise<void>
	{
		if (this.identity.tokens != null)
		{
			try
			{
				if (this.identity.accessTokenExpirationDateTime < DateTime.local())
				{
					await this.reauthenticate();
				}
				else
				{
					await this.verifyAccessTokenExpireDate();
				}
			}
			finally
			{
				await this.initial();
			}
		}
	}

	/**
	 * Unauthenticate the user, removing the authentication token stored on the device.
	 * @returns A promise that will be resolved with a boolean indicating whether unauthentication succeeded.
	 */
	public async unauthenticate(): Promise<boolean>
	{
		try
		{
			this.identity.logout();
			this._identity = undefined;
		}
		finally
		{
			this.configureInfrastructure();
		}

		return Promise.resolve(true);
	}

	/**
	 * Configures the infrastructure.
	 * Adds or removes the tokens to the set of default headers used by the `ApiClient`,
	 * and sets the user associated with log entries.
	 */
	public configureInfrastructure(): void
	{
		if (this.identity.tokens != null)
		{
			settings.infrastructure.api.defaults!.headers!["authorization"] = `Bearer ${this.identity.tokens.access}`;
			Log.setUser({
				id: this._identity?.partner?.id.toString(),
				type: "partner",
				authenticated: this._identity?.authenticated
			});
		}
		else
		{
			delete settings.infrastructure.api.defaults!.headers!["authorization"];
			Log.setUser(undefined);
		}
	}

	/**
	 * Verifies the access token's expire date
	 * This will also call a new re-authentication one minute before expiring.
	 */
	private async verifyAccessTokenExpireDate(): Promise<void>
	{
		clearTimeout(this._refreshTokenTimeout);

		if (this.identity.tokens == null)
		{
			return;
		}

		const tokenValidityInterval = DateTime.local().until(this.identity.accessTokenExpirationDateTime);
		if (!tokenValidityInterval.isValid)
		{
			await this.reauthenticate();
		}
		var tokenValidityTime = Duration.fromMillis(tokenValidityInterval.length());
		const oneMinute = Duration.fromMillis(1000 * 60);

		const refresh = tokenValidityTime.minus(oneMinute);

		this._refreshTokenTimeout = setTimeout(
			async () => await this.reauthenticate(),
			refresh.get("milliseconds")
		);
	}

	/**
	 * Attempts to reauthenticate the user using the authentication cookie stored on the device.
	 * @returns A promise that will be resolved with a boolean indicating whether reauthentication succeeded.
	 */
	public async reauthenticate(): Promise<boolean>
	{
		try
		{
			settings.infrastructure.api.defaults!.headers!["authorization"] = `Bearer ${this.identity.tokens?.refresh}`;
			const result = await this._apiClient.post("/authentication/refresh-access-token");
			this.identity.setTokens(result.data.accessToken, this.identity.tokens?.refresh!);
		}
		catch
		{
			this.unauthenticate();
			return false;
		}
		finally
		{
			this.authenticated();
		}

		return Promise.resolve(this.identity.tokens != null);
	}

	/**
	 * Authenticates and logs in the user.
	 * @param email The email used as login credentials.
	 * @param password The password used to log in.
	 * @param signal The abort signal to use, or undefined to use no abort signal.
	 * @returns A promise that will be resolved when the operation succeeds.
	 */
	public async logIn(email: string, password: string, signal?: AbortSignal): Promise<void>
	{
		const result = await this._apiClient.post("/authentication/login",
			{
				body: { email: email, password: password },
				signal: signal
			});

		this.identity.setTokens(result.data.accessToken, result.data.refreshToken);
		this.configureInfrastructure();

		this.verifyAccessTokenExpireDate();
	}

	/**
	 * Resets password and authenticates the user.
	 * @param password The new password for the user.
	 * @param confirmPassword The confirmed password.
	 * @param token The code used to identify a confirmed user.
	 * @param id The id of the user resetting their password.
	 * @param signal The abort signal to use, or undefined to use no abort signal.
	 * @returns A promise that will be resolved when the operation succeeds.
	 */
	public async resetPassword(password: string, confirmPassword: string, token: string, id: string, signal?: AbortSignal): Promise<void>
	{
		const result = await this._apiClient.post("/authentication/reset-password",
			{
				body: {
					password: password,
					confirmPassword: confirmPassword,
					token: token,
					id: id
				},
				signal: signal
			});

		this.identity.setTokens(result.data.accessToken, result.data.refreshToken);
		this.configureInfrastructure();
	}

	/**
	 * Resets password and authenticates the user.
	 * @param password The new password for the user.
	 * @param confirmPassword The confirmed password.
	 * @param token The code used to identify a confirmed user.
	 * @param id The id of the user resetting their password.
	 * @param signal The abort signal to use, or undefined to use no abort signal.
	 * @returns A promise that will be resolved when the operation succeeds.
	 */
	public async claimPartner(password: string, confirmPassword: string, token: string, id: string, receiveMarketingInformation: boolean, signal?: AbortSignal): Promise<void>
	{
		const result = await this._apiClient.post("/partner/claim",
			{
				body: {
					password: password,
					confirmPassword: confirmPassword,
					token: token,
					id: id,
					receiveMarketingInformation: receiveMarketingInformation
				},
				signal: signal
			});

		this.identity.setTokens(result.data.accessToken, result.data.refreshToken);
		this.configureInfrastructure();
	}

	/**
	 * Tries to reset the password for a user, who can't remember their password.
	 * @param email The email of the user, who can't remember their password.
	 * @param signal The abort signal to use, or undefined to use no abort signal.
	 * @returns A promise that will be resolved when the operation succeeds.
	 */
	public async forgotPassword(email: string, signal?: AbortSignal): Promise<void>
	{
		await this._apiClient.post("/authentication/forgot-password",
			{
				body: { email: email },
				signal: signal
			});
	}

	/**
	 * Gets the base user information using the access token.
	 * @param signal The abort signal to use, or undefined to use no abort signal.
	 * @returns A promise that will be resolved when the operation succeeds.
	 */
	public async initial(signal?: AbortSignal): Promise<boolean>
	{
		const result = await this._apiClient.get("/initial",
			{
				signal: signal
			});

		if ([401, 403].includes(result.response.status))
		{
			this.verifyAccessTokenExpireDate();

			return false;
		}

		this.identity.setInitialInformations(result.data);
		this.configureInfrastructure();

		return true;
	}
}
