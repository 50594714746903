import clone from "clone";

export class PartnerAdministratorSimple
{
	/**
	 * Creates a new instance of the type.
	 * @param data The response data from which the instance should be created.
	 */
	public constructor(data: any)
	{
		this.id = data.id;
		this.firstName = data.firstName;
		this.lastName = data.lastName;
	}

	public readonly id: number | undefined;

	public readonly firstName: string;

	public readonly lastName: string;

	/**
	 * Gets a clone of this instance, suitable for editing.
	 */
	public clone(): any
	{
		return clone(this);
	}

	public get fullName(): string
	{
		return `${this.firstName} ${this.lastName}`;
	}

	public get initials(): string
	{
		return `${this.firstName[0]}${this.lastName[0]}`;
	}
}
