import clone from "clone";
import { CompanyStatus } from "./company-status";

/**
 * The employee instance used in data tables.
 */
export class CompanySimple
{
  /**
   * Creates a new instance of the type.
   * @param data The response data from which the instance should be created.
   */
  public constructor(data: any)
  {
    this.id = data.id;
    this.companyName = data.companyName ?? "";
    this.onboardingDate = data.onboardingDate ?? undefined;
    this.status = this.companyStatusFromJSON(data.status);
    this.numberOfEmployeesOnboardedSinceBeginning = data.numberOfEmployeesOnboardedSinceBeginning;
    this.numberOfEmployeesOnboardedThisMonth = data.numberOfEmployeesOnboardedThisMonth;
    this.numberOfEmployeesInOnboarding = data.numberOfEmployeesInOnboarding;
    this.numberOfPotentialEmployees = data.numberOfPotentialEmployees;
  }

  public readonly id: number;

  public readonly companyName: string;

  public readonly onboardingDate: Date | undefined;

  public readonly numberOfEmployeesOnboardedSinceBeginning: number;

  public readonly numberOfEmployeesOnboardedThisMonth: number;

  public readonly numberOfEmployeesInOnboarding: number;

  public readonly numberOfPotentialEmployees: number;

  public readonly status: CompanyStatus;

  /**
   * Create a new instance of this using JSON data
   */
  public companyStatusFromJSON(value: number): CompanyStatus
  {
    if (value === 1)
    {
      return new CompanyStatus("missing-signature");
    }
    else if (value === 10)
    {
      return new CompanyStatus("remuneration");
    }
    else if (value === 15)
    {
      return new CompanyStatus("no-remuneration");
    }

    return new CompanyStatus("deactivated");
  }

  /**
   * Gets a clone of this instance, suitable for editing.
   */
  public clone(): any
  {
    return clone(this);
  }
}
