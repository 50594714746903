import clone from "clone";

export class CompanyTypeahead
{
	public constructor(data: any)
	{
		this.name = data.name;
		this.cvrNumber = data.cvr;
	}

	public readonly name: string;

	public readonly cvrNumber: string;

	/**
	 * Gets a clone of this instance, suitable for editing.
	 */
	public clone(): any
	{
		return clone(this);
	}
}
