import { CompanySimple, CompaniesService } from "partner-dashboard/model/dashboard/companies";
import { IdentityService } from "partner-dashboard/services/identity";
import { autoinject, bindable, bindingMode, computedFrom } from "aurelia-framework";
import { ThemeService, ToastService } from "shared/framework";
import { BaseModule } from "shared/framework/components/base-module/base-module";
import { Operation } from "shared/utilities";

/**
 * Represents the module.
 */
@autoinject
export class CompaniesModule extends BaseModule
{
	/**
	 * Creates a new instance of the type.
	 */
	public constructor(
		companiesService: CompaniesService,
		identityService: IdentityService,
		toastService: ToastService,
		themeService: ThemeService)
	{
		super();

		this._companiesService = companiesService;
		this._identityService = identityService;
		this._toastService = toastService;
    this._themeService = themeService;

		this._contructed = true;
	}

	protected readonly _companiesService: CompaniesService;
	protected readonly _identityService: IdentityService;
	protected readonly _toastService: ToastService;
  protected readonly _themeService: ThemeService;

	private readonly _contructed;

	@bindable({ defaultBindingMode: bindingMode.toView })
	protected updatePage: boolean;

	/**
	 * The items to represent in the table
	 */
	 protected companies: CompanySimple[] | undefined;
	 protected administratorFirstName: string | undefined;

	protected failed: boolean = false;


	/**
	 * Sorts the current list of companies
	 * @returns a sorted list of companies or undefined, if there is no data
	 */
	@computedFrom("companies")
	protected get sortedCompanies(): CompanySimple[] | undefined
	{
		return this.companies?.sort((a: CompanySimple, b: CompanySimple) =>
		{
			if(a.status.slug === 'missing-signature' && b.status.slug === 'missing-signature')
			{
				if(a.id < b.id)
				{
					return 1;
				}
				else
				{
					return -1;
				}
			}
			else if(a.status.slug === 'missing-signature')
			{
				return -1;
			}
			else if(b.status.slug === 'missing-signature')
			{
				return 1;
			}

			if(a.onboardingDate == null || b.onboardingDate == null)
			{
				if(a.id < b.id)
				{
					return 1;
				}
				else
				{
					return -1;
				}
			}

			if(a.onboardingDate < b.onboardingDate)
			{
				return -1;
			}
			else
			{
				return 1;
			}
		});
	}


	/**
	 * The most recent update operation
	 */
	protected updateOperation: Operation;

	/**
	 * Called by the framework when the module is activated.
	 * @returns A promise that will be resolved, when the module is activated.
	 */
	public async doActivate(): Promise<void>
	{
		this.update();

		if (
			window.localStorage.key(window.localStorage.length - 1) === "nulstil-adgangskode" ||
			window.localStorage.key(window.localStorage.length - 1) === "ny-administrator")
		{
			this._toastService.open(
				"success",
				{
					heading: "Din adgangskode er nu ændret."
				}
			)
		}
	}

	protected updatePageChanged(newValue: boolean): void
	{
		if (newValue)
		{
			this.update();
		}
	}

	/**
	 * Updates the page by fetching the latest data.
	 */
	 protected update(): void
	 {
		 // Returns if the object is not contructed.
		 // This is needed because the `observable` decorator called the change handler when the
		 // initial property value is set, which happens before the constructor is called
		 if (!this._contructed)
		 {
			 return;
		 }

		 if (this.updateOperation != null)
		 {
			 this.updateOperation.abort();
		 }

		 // Create and execute the operation
		 this.updateOperation = new Operation(async signal =>
		 {
			 this.failed = false;

			 try
			 {
				 // Fetch the data
				 const result = await this._companiesService.getCompanies(signal);

				 this.companies = result.companies;
				 this.administratorFirstName = this._identityService.identity.administrator.firstName;
			 }
			 catch (error: any)
			 {
				 this.failed = true;
				 this._toastService.open(
					 "error",
					 {
						 "message": error.message
					 }
				 )
			 }
		 })
	 }
}
