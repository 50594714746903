import { autoinject, bindable } from "aurelia-framework";

@autoinject
export class ModalImageCustomElement
{

	/**
	 * True if the section is expanded, otherwise false.
	 */
	@bindable
	public imageUrl: string;
}
