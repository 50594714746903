import { IdentityService } from "partner-dashboard/services/identity";
import { autoinject } from "aurelia-framework";
import { IValidation, ToastService } from "shared/framework";
import { Modal } from "shared/framework/services/modal";
import { Operation } from "shared/utilities";

@autoinject
export class ForgotPassword
{
	/**
	 * Creates a new instance of the type.
	 * @param modal The `Modal` instance representing the modal.
	 * @param identityService The `IdentityService` instance.
	 * @param toastService The `ToastService` instance.
	 */
	public constructor(modal: Modal, identityService: IdentityService, toastService: ToastService)
	{
		this._modal = modal;
		this._identityService = identityService;
		this._toastService = toastService;
	}

	private readonly _modal: Modal;
	private readonly _identityService: IdentityService;
	private readonly _toastService: ToastService;

	/**
	 * The validation for the modal.
	 */
	protected validation: IValidation;

	/**
	* The email of the user
	*/
	protected email: string;

	/**
	* The most recent update operation
	*/
	protected updateOperation: Operation;

	/**
	* Whether or not the operation has been finished
	*/
	protected operationFinished: boolean;

	/**
	 * Called when the cancel button is clicked.
	 */
	protected async onCloseClick(): Promise<void>
	{
		await this._modal.close();
	}

	/**
	 * Updates the page by fetching the latest data.
	 */
	protected async resetPassword(): Promise<void>
	{
		this.validation.active = true;

		if (!await this.validation.validate())
		{
			return;
		}

		if (this.updateOperation != null)
		{
			this.updateOperation.abort();
		}

		// Create and execute the operation
		this.updateOperation = new Operation(async signal =>
		{
			try
			{
				await this._identityService.forgotPassword(this.email, signal);

				this.operationFinished = true;
			}
			catch (error)
			{
				this._toastService.open(
					"error",
					{
						"error": error
					}
				)
			}
		})
	}
}
