import clone from "clone";

export class BookMeetingPageResponse
{
  /**
   * Creates a new instance of the type.
   * @param data The response data from which the instance should be created.
   */
  public constructor(data: any)
  {
		this.partnerName = data.partnerName;
    this.partnerAdministratorFirstName = data.partnerAdministrator.firstName;
    this.partnerAdministratorLastName = data.partnerAdministrator.lastName;
  }

	public readonly partnerName: string;

  public readonly partnerAdministratorFirstName: string;
  public readonly partnerAdministratorLastName: string;

  /**
   * Gets a clone of this instance, suitable for editing.
   */
  public clone(): any
  {
    return clone(this);
  }
}
