import { textCase } from "shared/utilities";

/**
 * Represents the slug identifying a `CompanyStatus`.
 */
export type CompanyStatusSlug = keyof typeof CompanyStatus.values;

/**
 * Represents the status of an Company.
 */
export class CompanyStatus
{
  /**
   * Creates a new instance of the type.
   * @param slug The slug identifying the status of the Company.
   */
  public constructor(slug: CompanyStatusSlug)
  {
    this.slug = textCase(slug, "pascal", "kebab") as any;
    Object.assign(this, CompanyStatus.values[this.slug]);
  }

  public slug: CompanyStatusSlug;
  public name: string;
  public value: number;

  /**
   * Gets the data representing this instance.
   */
  public toJSON(): any
  {
    return this.value;
  }

  /**
   * The supported values.
   */
  public static readonly values =
  {
    "missing-signature":
    {
      name: "Aftale tilsendt",
      value: 1
    },
    "remuneration":
    {
      name: "Aktiv",
      value: 10
    },
    "no-remuneration":
    {
      name: "Aktiv",
      value: 15
    },
    "deactivated":
    {
      name: "Deaktiveret",
      value: 20
    },
  };
}
