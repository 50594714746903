import { autoinject, bindable, bindingMode } from "aurelia-framework";
import { ToastService } from "shared/framework";
import { BaseModule } from "shared/framework/components/base-module/base-module";
import { Operation } from "shared/utilities";
import { BookMeetingService } from "partner-dashboard/model/dashboard/";

/**
 * Represents the module.
 */
@autoinject
export class BookMeetingModule extends BaseModule
{
	public constructor(
		toastService: ToastService,
		bookMeetingService: BookMeetingService)
	{
		super();

		this._toastService = toastService;
		this._bookMeetingService = bookMeetingService;

		this._contructed = true;
	}

	private readonly _toastService: ToastService;
	private readonly _bookMeetingService: BookMeetingService;

	private readonly _contructed;

	@bindable({ defaultBindingMode: bindingMode.toView })
	protected updatePage: boolean;

	protected partnerName: string | undefined;
	protected partnerAdministratorFirstName: string | undefined;
	protected partnerAdministratorLastName: string | undefined;

	/**
	 * Called by the framework when the module is activated.
	 * @returns A promise that will be resolved, when the module is activated.
	 */
	 public async doActivate(): Promise<void>
	 {
		 this.update();
	 }

	 protected updatePageChanged(newValue: boolean): void
	 {
		 if (newValue)
		 {
			 this.update();
		 }
	 }

	 /**
		* Updates the page by fetching the latest data.
		*/
		protected update(): void
		{
		 // Returns if the object is not contructed.
		 // This is needed because the `observable` decorator called the change handler when the
		 // initial property value is set, which happens before the constructor is called
		 if (!this._contructed)
		 {
			 return;
		 }

			if (this.updateOperation != null)
			{
				this.updateOperation.abort();
			}

			// Create and execute the operation
			this.updateOperation = new Operation(async signal =>
			{
				try
				{
					// Fetch the data
					const result = await this._bookMeetingService.getBookMeeting(signal);

					this.partnerName = result.partnerName;
					this.partnerAdministratorFirstName = result.partnerAdministratorFirstName;
					this.partnerAdministratorLastName = result.partnerAdministratorLastName;
				}
				catch (error: any)
				{
					this._toastService.open(
						"error",
						{
							"message": error.message
						}
					)
				}
			})
		}
}
