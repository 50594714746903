import clone from "clone";
import { CompanyTypeahead } from "..";

export class Company
{
	public constructor()
	{
		this.name = "";
		this.cvrNumber = "";
		this.phoneNumber = "";
	}

	public name: string;

	public cvrNumber: string;

	public phoneNumber: string;

	public mergeQueriedCompany(company: CompanyTypeahead): Company
	{
		this.cvrNumber = company.cvrNumber;
		this.name = company.name;

		return this;
	}

	/**
	 * Gets a clone of this instance, suitable for editing.
	 */
	public clone(): any
	{
		return clone(this);
	}
}
