import clone from "clone";

export class CompanyAdministrator
{
	public constructor()
	{
		this.firstName = "";
		this.lastName = "";
		this.email = "";
	}

	public firstName: string;

	public lastName: string;

	public email: string;

	/**
	 * Gets a clone of this instance, suitable for editing.
	 */
	public clone(): any
	{
		return clone(this);
	}
}
