export class ModuleActivateStartedEvent
{
	public constructor(
		moduleName: string | undefined)
	{
		this.moduleName = moduleName ?? "";
	}

	public readonly moduleName: string;
}
