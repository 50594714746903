import { Cookies } from "shared/infrastructure";
import { PartnerAdministratorSimple, PartnerInitial } from "..";
import { computedFrom, autoinject } from "aurelia-framework";
import njwt from "njwt";
import { DateTime } from "luxon";

const enum Path
{
	AccessToken = "access-token",
	RefreshToken = "refresh-token",
	Locale = "locale"
}

/**
 * Represents the identity of an authenticated user.
 */
 @autoinject
export class Identity
{
	/**
	 * Creates a new instance of the type.
	 */
	public constructor()
	{

		const accessToken = this._cookies.get(Path.AccessToken);
		const refreshToken = this._cookies.get(Path.RefreshToken);

		if (accessToken != null && refreshToken != null)
		{
			this.tokens = { access: accessToken, refresh: refreshToken };
		}
	}

	private readonly _cookies: Cookies = new Cookies();

	/**
	 * The tokens to use when accessing the API.
	 */
	public tokens: { refresh: string; access: string } | undefined;

	/**
	 * The partner administered by the user.
	 */
	public partner: PartnerInitial | undefined;

	/**
	 * The information of the user logged in.
	 */
	public administrator: PartnerAdministratorSimple;

	/**
	 * Sets the access and refresh tokens for the user
	 * and checks the user as authenticated
	 */
	public setTokens(accessToken: string, refreshToken: string): void
	{
		this._cookies.set(Path.RefreshToken, refreshToken, { path: "/" });
		this._cookies.set(Path.AccessToken, accessToken, { path: "/" });
		this.tokens = { access: accessToken, refresh: refreshToken };
	}

	/**
	 * Gets and fill out the data from the initial call
	 */
	public setInitialInformations(data: any): void
	{
		this.partner = new PartnerInitial(data.partner);
		this.administrator = new PartnerAdministratorSimple(data.administrator);
	}

	/**
	 * Whether or not the user is authenticated.
	 */
	 @computedFrom("tokens")
	public get authenticated(): boolean
	{
		if (this.tokens == null)
		{
			return false;
		}

		const expires = this.accessTokenExpirationDateTime;

		if (expires < DateTime.local())
		{
			return false;
		}

		return true;
	}

	public get accessTokenExpirationDateTime(): DateTime
	{
		let expirationDateInSeconds: number;

		try
		{
			const jwt = njwt.verify(this.tokens?.access);
			expirationDateInSeconds = jwt.parsedBody.exp;
		}
		catch(error: any)
		{
			if (error.parsedBody == null)
			{
				throw new Error(`Could not parse the JWT token. ${error.message}`);
			}

			expirationDateInSeconds = error.parsedBody.exp;
		}

		return DateTime.fromSeconds(expirationDateInSeconds);
	}

	/**
	 * Resets the identity of the user
	 */
	private reset(): void
	{
		this.tokens = undefined;
		this.partner = undefined;

		this._cookies.set(Path.AccessToken, undefined, { path: "/" });
		this._cookies.set(Path.RefreshToken, undefined, { path: "/" });
	}

	/**
	 * The logout triggers a reset of the identity of the user
	 */
	public logout(): void
	{
		this.reset();
	}
}
