import * as Sentry from "@sentry/browser";
import { Router } from "aurelia-router";
import { autoinject, bindable } from "aurelia-framework";
import { IValidation, ToastService } from "shared/framework";
import { BaseModule } from "shared/framework/components/base-module/base-module";
import { ApiValidationError } from "shared/infrastructure";
import { Operation } from "shared/utilities";
import { InviteCompanyService, CompanyAdministrator, Company, CompanyTypeahead } from "partner-dashboard/model/dashboard/";

/**
 * Represents the module.
 */
@autoinject
export class InviteCompanyModule extends BaseModule
{
	public constructor(
		toastService: ToastService,
		inviteCompanyService: InviteCompanyService,
		router: Router)
	{
		super();

		this._toastService = toastService;
		this._inviteCompanyService = inviteCompanyService;
		this._router = router;
	}

	private readonly _toastService: ToastService;
	private readonly _inviteCompanyService: InviteCompanyService;
	private readonly _router: Router;

	protected companyInformationValidation: IValidation;

	protected companySearchOperation: Operation;

	protected administrator: CompanyAdministrator = new CompanyAdministrator();

	protected company: Company = new Company();

  @bindable
	protected companySelected: CompanyTypeahead | undefined;

	public doActivate(): void
	{}

	protected warnProductionGrandhoodEmployee(): void
	{
		if (ENVIRONMENT.name === "production" && this.administrator.email.includes("@grandhood.dk"))
		{
			alert("ER DU SIKKER PÅ AT DU GERNE VIL BRUGE DEN EMAIL I PRODUKTION?");
		}
	}

	protected onSearchInputFocus(lookupMenu: any): void
	{
		lookupMenu.open = true;
	}

	protected async onInviteCompany(): Promise<void>
	{
		this.companyInformationValidation.active = true;

		if (!await this.companyInformationValidation.validate())
		{
			return;
		}

		if (this.updateOperation != null)
		{
			this.updateOperation.abort();
		}

		// Create and execute the operation
		this.updateOperation = new Operation(async signal =>
		{
			try
			{
				await this._inviteCompanyService.inviteCompany(this.company.mergeQueriedCompany(this.companySelected!), this.administrator, signal);

				this._router.navigate("virksomheder", { replace: true });
			}
			catch (error: any)
			{
				if(error instanceof ApiValidationError)
				{
					if(error.data.title == "Company structure is unsupported.")
					{
						this._toastService.open(
							"error",
							{
								"message": "Vi understøtter desværre ikke din virksomhedsstruktur. Kontakt os gerne for mere information."
							}
						);
					}
					else if(error.data.title == "Company branch is unsupported.")
					{
						this._toastService.open(
							"error",
							{
								"message": "Vi understøtter desværre ikke branchen som din virksomhed er i. Kontakt os gerne for mere information."
							}
						);
					}
					else if(error.data.title == "Company administrator already exists with provided e-mail.")
					{
						this._toastService.open(
							"error",
							{
								"message": "Virksomhedsadministratoren er allerede tilknyttet en virksomhed. Benyt venligst en anden e-mail adresse."
							}
						);
					}
					else if(error.data.title == "Company already exists with provided CVR number.")
					{
						this._toastService.open(
							"error",
							{
								"message": "Din virksomhed er allerede i vores system. Har du eller en kollega tidligere være forbi os? Kontakt os gerne for mere information."
							}
						);
					}
					else if(error.data.title == "One or more validation errors occurred.")
					{
						// The error is not expected as the Frontend should handle
						// all validation errors regarding the input provided.
						// This is a catch to ensure a friendly error message to
						// the user, but the error is logged as to notify that
						// a change needs to be made in the Frontend.
						Sentry.captureException(error);

						this._toastService.open(
							"error",
							{
								"message": "En eller flere af felterne mangler at blive udfyldt."
							}
						);
					}
					else
					{
						// The error is not expected, but was not seen as
						// an error from the Backend. So the error is
						// logged to Sentry from the Frontend.
						Sentry.captureException(error);

						this._toastService.open(
							"error",
							{
								"message": "Vi har tekniske problemer lige nu. Skriv på support@grandhood.dk hvis problemet bliver ved."
							}
						);
					}
				}
				else
				{
					if ([401, 403].includes(error.response.status))
					{
						this._toastService.open(
							"error",
							{
								"message": "Vi kunne ikke genkende mail eller adgangskode. Har du glemt din adgangskode?"
							}
						);
					}
					else
					{
						this._toastService.open(
							"error",
							{
								"message": "Vi har tekniske problemer lige nu. Skriv på support@grandhood.dk hvis problemet bliver ved."
							}
						)
					}
					console.log("An error occurred.\n", error, signal);
				}
			}
		})
	}
}
