import { IdentityService } from "partner-dashboard/services/identity";
import { autoinject } from "aurelia-framework";
import { ApiClient } from "shared/infrastructure";
import { CompaniesPageResponse } from "..";

/**
 * Represents a service that manages companies.
 */
 @autoinject
 export class CompaniesService
 {
	/**
	 * Creates a new instance of the type.
	 * @param apiClient The `ApiClient` instance.
	 * @param identityService The `IdentityService` instance.
	 */
	public constructor(apiClient: ApiClient, identityService: IdentityService)
	{
		this._apiClient = apiClient;
		this._identityService = identityService;
	}

	private readonly _apiClient: ApiClient;
	private readonly _identityService: IdentityService;

	/**
	 * Gets all employees visible to the current user.
	 * @param signal The abort signal to use, or undefined to use no abort signal.
	 * @returns A promise that will be resolved with the employees.
	 */
   public async getCompanies(signal?: AbortSignal): Promise<CompaniesPageResponse>
   {
     const result = await this._apiClient.get(`/${this._identityService.identity.partner?.id}/companies`,
       {
         signal
       }
     );

     return new CompaniesPageResponse(result.data);
   }
 }
