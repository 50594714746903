import clone from "clone";

export class PartnerInitial
{
	/**
	 * Creates a new instance of the type.
	 * @param data The response data from which the instance should be created.
	 */
	public constructor(data: any)
	{
		this.id = data.id;
		this.name = data.name;
	}

	public readonly id: number;

	public readonly name: string;

	/**
	 * Gets a clone of this instance, suitable for editing.
	 */
	public clone(): any
	{
		return clone(this);
	}
}
