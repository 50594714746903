import clone from "clone";
import { CompanySimple } from "./company-simple";

export class CompaniesPageResponse
{
  /**
   * Creates a new instance of the type.
   * @param data The response data from which the instance should be created.
   */
  public constructor(data: any)
  {
		this.companies = data.companies.map((data: any) => new CompanySimple(data));
  }

	public readonly companies: CompanySimple[];

  /**
   * Gets a clone of this instance, suitable for editing.
   */
  public clone(): any
  {
    return clone(this);
  }
}
