import clone from "clone";
import { Remuneration } from "../entities/remuneration"

export class RemunerationsPageResponse
{
  /**
   * Creates a new instance of the type.
   * @param data The response data from which the instance should be created.
   */
  public constructor(data: any)
  {
    this.employeesPotential = new Remuneration(data.employeesPotential);
    this.companiesPotential = new Remuneration(data.companiesPotential);
    this.currentMonth = new Remuneration(data.currentMonth);
    this.lastMonth = new Remuneration(data.lastMonth);
    this.lastThreeMonths = new Remuneration(data.lastThreeMonths);
    this.total = new Remuneration(data.total);
  }

	public readonly employeesPotential: Remuneration;
  public readonly companiesPotential: Remuneration;
  public readonly currentMonth: Remuneration;
  public readonly lastMonth: Remuneration;
  public readonly lastThreeMonths: Remuneration;
  public readonly total: Remuneration;

  /**
   * Gets a clone of this instance, suitable for editing.
   */
  public clone(): any
  {
    return clone(this);
  }
}
