import { IdentityService } from "partner-dashboard/services/identity";
import { autoinject } from "aurelia-framework";
import { ApiClient } from "shared/infrastructure";
import { RemunerationsPageResponse } from "..";

/**
 * Represents a service that manages remunerations.
 */
 @autoinject
 export class RemunerationsService
 {
	/**
	 * Creates a new instance of the type.
	 * @param apiClient The `ApiClient` instance.
	 * @param identityService The `IdentityService` instance.
	 */
	public constructor(apiClient: ApiClient, identityService: IdentityService)
	{
		this._apiClient = apiClient;
		this._identityService = identityService;
	}

	private readonly _apiClient: ApiClient;
	private readonly _identityService: IdentityService;

   public async getRemunerations(signal?: AbortSignal): Promise<RemunerationsPageResponse>
   {
     const result = await this._apiClient.get(`/${this._identityService.identity.partner?.id}/remunerations`,
       {
         signal
       }
     );

     return new RemunerationsPageResponse(result.data);
   }
 }
