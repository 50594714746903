import { IdentityService } from "partner-dashboard/services/identity";
import { autoinject } from "aurelia-framework";
import { ApiClient } from "shared/infrastructure";

@autoinject
export class AdministratorService
{
	private readonly _apiClient: ApiClient;
	private readonly _identityService: IdentityService;

	public constructor(
		apiClient: ApiClient,
		identityService: IdentityService)
	{
		this._apiClient = apiClient;
		this._identityService = identityService;
	}

	public async addAdministrator(
		firstName: string,
		lastName: string,
		email: string,
		signal?: AbortSignal): Promise<void>
	{
		await this._apiClient.post(
			`/${this._identityService.identity.partner?.id}/administrator/add`,
			{
				body: { firstName: firstName, lastName: lastName, email: email },
				signal
			}
		);
	}

	public async deactivateAdministrator(administratorId: number, signal?: AbortSignal): Promise<void>
	{
		await this._apiClient.post(
			`/${this._identityService.identity.partner?.id}/administrator/${administratorId}/deactivate`,
			{
				signal
			}
		);
	}

	/**
	 * Creates a password for the new user and authenticates the user.
	 * @param password The new password for the user.
	 * @param confirmPassword The confirmed password.
	 * @param token The code used to identify a confirmed user.
	 * @param id The id of the user resetting their password.
	 * @param receiveMarketingInformation If the user consents to receive marketing information.
	 * @param signal The abort signal to use, or undefined to use no abort signal.
	 * @returns A promise that will be resolved when the operation succeeds.
	 */
	public async acceptInvite(password: string, confirmPassword: string, token: string, id: string, receiveMarketingInformation: boolean, signal?: AbortSignal): Promise<void>
	{
		const result = await this._apiClient.post(
			`/administrator/accept-invite`,
			{
				body: {
					password: password,
					confirmPassword: confirmPassword,
					token: token,
					id: id,
					receiveMarketingInformation: receiveMarketingInformation
				},
				signal: signal
			});

		this._identityService.identity.setTokens(result.data.accessToken, result.data.refreshToken);
		this._identityService.configureInfrastructure();
	}
}
