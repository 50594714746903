import { RemunerationsPageResponse, RemunerationsService } from "partner-dashboard/model/dashboard/remunerations";
import { IdentityService } from "partner-dashboard/services/identity";
import { autoinject, bindable, bindingMode } from "aurelia-framework";
import { ThemeService, ToastService } from "shared/framework";
import { BaseModule } from "shared/framework/components/base-module/base-module";
import { Operation } from "shared/utilities";

/**
 * Represents the module.
 */
@autoinject
export class RemunerationsModule extends BaseModule
{
	/**
	 * Creates a new instance of the type.
	 */
	public constructor(
		remunerationsService: RemunerationsService,
		identityService: IdentityService,
		toastService: ToastService,
		themeService: ThemeService)
	{
		super();

		this._remunerationsService = remunerationsService;
		this._identityService = identityService;
		this._toastService = toastService;
    this._themeService = themeService;

		this._contructed = true;
	}

	protected readonly _remunerationsService: RemunerationsService;
	protected readonly _identityService: IdentityService;
	protected readonly _toastService: ToastService;
  protected readonly _themeService: ThemeService;

	private readonly _contructed;

	@bindable({ defaultBindingMode: bindingMode.toView })
	protected updatePage: boolean;

	protected remunerations: RemunerationsPageResponse | undefined;

	protected failed: boolean = false;

	/**
	 * The most recent update operation
	 */
	protected updateOperation: Operation;

	/**
	 * Called by the framework when the module is activated.
	 * @returns A promise that will be resolved, when the module is activated.
	 */
	public async doActivate(): Promise<void>
	{
		this.update();


	}

	protected updatePageChanged(newValue: boolean): void
	{
		if (newValue)
		{
			this.update();
		}
	}

	/**
	 * Updates the page by fetching the latest data.
	 */
	 protected update(): void
	 {
		 // Returns if the object is not contructed.
		 // This is needed because the `observable` decorator called the change handler when the
		 // initial property value is set, which happens before the constructor is called
		 if (!this._contructed)
		 {
			 return;
		 }

		 if (this.updateOperation != null)
		 {
			 this.updateOperation.abort();
		 }

		 // Create and execute the operation
		 this.updateOperation = new Operation(async signal =>
		 {
			 this.failed = false;

			 try
			 {
				 // Fetch the data
				 this.remunerations = await this._remunerationsService.getRemunerations(signal);
			 }
			 catch (error: any)
			 {
				 this.failed = true;
				 this._toastService.open(
					 "error",
					 {
						 "message": error.message
					 }
				 )
			 }
		 })
	 }
}
