import { singleton } from "aurelia-framework";
import * as Sentry from "@sentry/browser";

/**
 * Helper service adds span for api calls.
 */
@singleton()
export class SentryApiHandler
{
	public start(
		method: string,
		path: string
	): void
	{
		const sentryHub = Sentry.getCurrentHub();
		const scope = sentryHub.pushScope();
		const parentSpan = scope.getSpan();
		scope.setSpan(parentSpan?.startChild(
			{
				op: "http call",
				description: `${method} ${path}`,
			}
		));
	}

	public get traceHeader(): string | undefined
	{
		const span = Sentry
			.getCurrentHub()
			?.getScope()
			?.getSpan();

		return span?.toTraceparent();
	}

	public finish(status: number): void
	{
		const sentryHub = Sentry.getCurrentHub();
		const scope = sentryHub.getScope();
		if (scope?.getSpan())
		{
			scope.getSpan()?.setHttpStatus(status);
			scope.getSpan()?.finish();
		}

		sentryHub.popScope();
	}
}
