export class ModuleActivateFinishedEvent
{
	public constructor(
		moduleName: string | undefined,
		status: "error" | "aborted" | "success",
		error?: Error)
	{
		this.moduleName = moduleName ?? "";
		this.status = status;
		this.error = error;
	}

	public readonly moduleName: string;
	public readonly status: "error" | "aborted" | "success";
	public readonly error: Error | undefined;
}
