import clone from "clone";

/**
 * The remuneration structure
 */
export class Remuneration
{
  /**
   * Creates a new instance of the type.
   * @param data The response data from which the instance should be created.
   */
  public constructor(data: any)
  {
    this.numberOfEmployees = data.numberOfEmployees;
    this.remunerationPerEmployee = data.remunerationPerEmployee;
    this.totalRemuneration = data.totalRemuneration;
  }

  public readonly numberOfEmployees: number;
  public readonly remunerationPerEmployee: number;
  public readonly totalRemuneration: number;

  /**
   * Gets a clone of this instance, suitable for editing.
   */
  public clone(): any
  {
    return clone(this);
  }
}
