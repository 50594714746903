import { IdentityService } from "partner-dashboard/services/identity";
import { autoinject } from "aurelia-framework";
import { ApiClient } from "shared/infrastructure";
import { CompanyAdministrator, Company, CompanyTypeahead } from "..";

@autoinject
export class InviteCompanyService
{
	private readonly _apiClient: ApiClient;
	private readonly _identityService: IdentityService;

	public constructor(
		apiClient: ApiClient,
		identityService: IdentityService)
	{
		this._apiClient = apiClient;
		this._identityService = identityService;
	}

	public async searchForCompany(
		query: string,
		signal?: AbortSignal): Promise<CompanyTypeahead[]>
	{
		const response = await this._apiClient.get(
			`/${this._identityService.identity.partner?.id}/company/typeahead`,
			{
				query: { companyName: query },
				signal
			}
		);

		return response.data.map(companyData => new CompanyTypeahead(companyData));
	}

	public async inviteCompany(
		company: Company,
		administrator: CompanyAdministrator,
		signal?: AbortSignal): Promise<void>
	{
		await this._apiClient.post(
			`/${this._identityService.identity.partner?.id}/company/invite`,
			{
				body: { administrator: administrator, ...company },
				signal
			}
		);
	}
}
