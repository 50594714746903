import { autoinject, PLATFORM } from "aurelia-framework";
import { RouterConfiguration, Router } from "aurelia-router";

/**
 * Represents the module.
 */
@autoinject
export class DashboardModule
{
	public configureRouter(config: RouterConfiguration, router: Router): void
	{
		let routeConfig = [
			{
				name: "default",
				route: "",
				redirect: "vederlag"
			},
			{
				name: "remunerations",
				route: "vederlag",
				moduleId: PLATFORM.moduleName("./modules/remunerations/remunerations"),
				title: "Vederlag",
				nav: true,
				icon: "people"
			},
			{
				name: "companies",
				route: "virksomheder",
				moduleId: PLATFORM.moduleName("./modules/companies/companies"),
				title: "Virksomheder",
				nav: true,
				icon: "building"
			},
			{
				name: "invite-company",
				route: "inviter-virksomhed",
				moduleId: PLATFORM.moduleName("./modules/invite-company/invite-company"),
				title: "Invitér virksomhed",
				nav: true,
				icon: "payment-card"
			},
			{
				name: "book-meeting",
				route: "book-moede",
				moduleId: PLATFORM.moduleName("./modules/book-meeting/book-meeting"),
				title: "Book møde",
				nav: true,
				icon: "chat-bubbles"
			},
		]

		config.map(routeConfig);
	}
}
