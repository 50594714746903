import { singleton } from "aurelia-framework";
import { EventAggregator } from "aurelia-event-aggregator";
import * as Sentry from "@sentry/browser";
import { SpanStatus } from "@sentry/tracing";
import { ModuleActivateFinishedEvent } from "../events/modules/activate-finished-event";
import { ModuleActivateStartedEvent } from "../events/modules/activate-started-event";

/**
 * Helper service that subscribes to events that Sentry must log.
 */
@singleton()
export class SentryEvents
{
	/**
	 * Creates a new instance of the type.
	 * @param eventAggregator The `EventAggregator` instance.
	 */
	public constructor(eventAggregator: EventAggregator)
	{
		eventAggregator.subscribe("grandhood:module:activate:started", (event: ModuleActivateStartedEvent) =>
		{
			const sentryHub = Sentry.getCurrentHub();
			const scope = sentryHub.pushScope();
			scope.setSpan(sentryHub.startTransaction(
			{
				name: event.moduleName,
				op: "navigation"
			}));
		});

		eventAggregator.subscribe("grandhood:module:activate:finished", (event: ModuleActivateFinishedEvent) =>
		{
			const sentryHub = Sentry.getCurrentHub();
			const transaction = sentryHub
				?.getScope()
				?.getTransaction();

			if(transaction == null)
			{
				sentryHub.popScope();
				return;
			}

			if (event.status === "success")
			{
				transaction.setStatus(SpanStatus.Ok);
				transaction.finish();
			}
			else if (event.status === "error")
			{
				transaction.setStatus(SpanStatus.InternalError);
				transaction.finish();
			}
			else if(event.status === "aborted")
			{
				transaction.setStatus(SpanStatus.Aborted);
				transaction.finish();
			}
			else
			{
				transaction.setStatus(SpanStatus.UnknownError);
				transaction.finish();
			}

			sentryHub.popScope();
		});
	}
}
